import React, { useState } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import actionCreators from "./actions";
 import { getHashValues } from "./utils";
import { Modal } from "@material-ui/core";
import './Modal.scss';
export const DemoApp = (props) => {
  const [openModal, setopenModal] = useState(false)
  const handleOpenModal = (e) => {
    setopenModal(true);
  }
  const handleCloseModal = (e) => {
    setopenModal(false);
  }
  // handlers for user actions
  // ------------------------------------------------------------------------------------------

  const handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar;
    //let title = prompt("Ingresa nombre de cliente");
    handleOpenModal();
    // calendarApi.unselect(); // clear date selection

    // if (title) {
    //   calendarApi.addEvent(
    //     {
    //       // will render immediately. will call handleEventAdd
    //       title,
    //       start: selectInfo.startStr,
    //       end: selectInfo.endStr,
    //       allDay: selectInfo.allDay,
        
    //     },
    //     true
    //   ); // temporary=true, will get overwritten when reducer gives new events
    // }
  };

  const handleEventClick = (clickInfo) => {
    // if (
    //   alert(
    //     `Are you sure you want to delete the event '${clickInfo.event.title}'`
    //   )
    // ) {
    //   clickInfo.event.remove(); // will render immediately. will call handleEventRemove
    // }
    console.log(clickInfo)
  };

  // handlers that initiate reads/writes via the 'action' props
  // ------------------------------------------------------------------------------------------

  const handleDates = (rangeInfo) => {
    props
      .requestEvents(rangeInfo.startStr, rangeInfo.endStr)
      .catch(reportNetworkError);
  };

  const handleEventAdd = (addInfo) => {
    console.log(addInfo)
    props.createEvent(addInfo.event.toPlainObject()).catch(() => {
      reportNetworkError();
      addInfo.revert();
    });
  };

  const handleEventChange = (changeInfo) => {
    props.updateEvent(changeInfo.event.toPlainObject()).catch(() => {
      reportNetworkError();
      changeInfo.revert();
    });
  };

  const handleEventRemove = (removeInfo) => {
    props.deleteEvent(removeInfo.event.id).catch(() => {
      reportNetworkError();
      removeInfo.revert();
    });
  };


const renderEventContent = (eventInfo)=> {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}

const renderSidebarEvent = (plainEventObject) => {
  return (
    <li key={plainEventObject.id}>
      <b>
        {formatDate(plainEventObject.start, {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </b>
      <i>{plainEventObject.title}</i>
    </li>
  );
}

const reportNetworkError =()=> {
  alert("This action could not be completed");
}



  return (
    <div className="demo-app">
      {/* {renderSidebar()} */}
      <div className="demo-app-main">
      <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            initialView='dayGridMonth'
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            datesSet={handleDates}
            select={handleDateSelect}
            events={props.events}
            eventContent={renderEventContent} // custom render function
            eventClick={handleEventClick}
            eventAdd={handleEventAdd}
            eventChange={handleEventChange} // called for drag-n-drop/resize
            eventRemove={handleEventRemove}
          />
        <Modal  open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="modal-event">
         <div  className="modal-content">
         <h1>Agendamiento</h1>
         </div>
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps =()=> {
  const getEventArray = createSelector(
    (state) => state.eventsById,
   getHashValues
  );

  return (state) => {
    return {
      events: getEventArray(state),
      weekendsVisible: state.weekendsVisible,
    };
  };
}
export default connect(mapStateToProps,actionCreators)(DemoApp)