import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import StorefrontRoundedIcon from "@material-ui/icons/StorefrontRounded";
import PetsRoundedIcon from "@material-ui/icons/PetsRounded";

// // import * as ducks from "../reducers";
// import { bindActionCreators } from "redux";
// import * as userReducer from "../User/userReducer";
// import {
//   getProfesionalAction,
//   getUserAction,
//   setProfesionalAction,
//   setUserAction,
// } from "../User/userAction";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { Button, Input, MenuList, TextField } from "@material-ui/core";
import GoogleLogin from "react-google-login";
import { useHistory } from "react-router-dom";
import "./Header.scss";
import { Loader } from "../Components/Loader/Loader";
// import AuthLive from "../Pages/AuthLive/AuthLive";
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

const mapStateToProps = (state) => {
  return state;
};

// const mapDispatchToProps = dispatch => bindActionCreators(ducks, dispatch);
const mapDispatchToProps = (dispatch) => ({
  // setUserAction: (data) => dispatch(setUserAction(data)),
  // getUserAction: () => dispatch(getUserAction),
  // getProfesionalAction: () => dispatch(getProfesionalAction),
  // setProfesionalAction: (data) => dispatch(setProfesionalAction(data)),
});
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sectionButtonDesktop: {
    cursor: "pointer",
    marginLeft: theme.spacing(4),
    fontSize: 16,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      ///display: "none",
      marginLeft: theme.spacing(0),
    },
  },
  sectionButtonMobile: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
    fontSize: 16,
    display: "flex",
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  buttonMobile: {
    minWidth: 45,
  },
  title: {
    display: "none",
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "50%",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  paper: {
    position: "absolute",
    //width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "95%",
    },
  },
  buttonLogin: {
    width: "100%",
  },
  btnGoogle: {
    width: "100%",
  },
  buttonRegister: {
    width: "100%",
    backgroundColor: "black",
    color: "white",
  },
}));

export const Header = (props) => {
  console.log(props.user);
  const [User, setUser] = useState(() => {
    return { ...props.user };
  });
  let history = useHistory();
  const { callback } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMessage, setanchorMessage] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [email, setemail] = useState("");
  const [pass, setpass] = useState("");
  // useEffect(() => {
  //   setUser(props.user);
  //   return () => {};
  // }, [props.user]);
  const [open, setOpen] = React.useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleIniciarSesion = () => {
    console.log("handleIniciarSesion");
    setAnchorEl(null);
    handleMobileMenuClose();
    handleOpenModal();
  };
  const handleMobileMenuOpen = (event) => {
    console.log("handleMobileMenuOpen : ", event.currentTarget);
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleSesionClose = () => {
    localStorage.removeItem("user");
    handleMenuClose();
    props.setUserAction(null);
    props.setUserAction({ isLogin: false });
    history.push("/");
    window.location.reload();
  };
  const handleClickMenu = () => {};

  const menuId = "primary-search-account-menu";
  const menuItems = [];
  menuItems.push(
    <MenuItem key={menuItems.length} onClick={handleMenuClose}>
      Mi perfil
    </MenuItem>
  );
  menuItems.push(
    <MenuItem key={menuItems.length} onClick={handleSesionClose}>
      Cerrar sesion
    </MenuItem>
  );
  const openMessageMenu = (event) => {
    setanchorMessage(event.currentTarget);
  };

  const closeMessageMenu = () => {
    setanchorMessage(null);
  };

  const goToExploreClinicas = () => {
    history.push("/explore-clinicas");
  };
  const goToExploreTiendas = () => {
    history.push("/explore-tiendas");
  };
  // const messageMenu =
  //   props.user !== null &&
  //   props.user &&
  //   props.user.messages === undefined ? null : (
  //     <Menu
  //       id="simple-menu"
  //       anchorEl={anchorMessage}
  //       anchorOrigin={{
  //         vertical: "bottom",
  //         horizontal: "left",
  //       }}
  //       transformOrigin={{
  //         vertical: "top",
  //         horizontal: "right",
  //       }}
  //       keepMounted
  //       open={Boolean(anchorMessage)}
  //       onClose={closeMessageMenu}
  //     >
  //       {props.user.messages.length <= 0 ? (
  //         <MenuItem>Sin mensajes</MenuItem>
  //       ) : (
  //         props.user !== null &&
  //         props.user &&
  //         props.user.messages.map((mess, i) => {
  //           return (
  //             <MenuItem key={i} onClick={closeMessageMenu}>
  //               <b>{mess.author}</b>: {mess.message}
  //             </MenuItem>
  //           );
  //         })
  //       )}
  //     </Menu>
  //   );
  // const renderMenu =
  //   props.user.isLogin === false ? (
  //     <Menu
  //       anchorEl={anchorEl}
  //       anchorOrigin={{ vertical: "top", horizontal: "right" }}
  //       id={menuId}
  //       keepMounted
  //       transformOrigin={{ vertical: "top", horizontal: "right" }}
  //       open={isMenuOpen}
  //       onClose={handleMenuClose}
  //       onClick={handleClickMenu}
  //       style={{ minHeight: "3rem" }}
  //     >
  //       <MenuItem key={0} onClick={handleIniciarSesion}>
  //         Iniciar Sesion
  //       </MenuItem>
  //     </Menu>
  //   ) : (
  //     <Menu
  //       anchorEl={anchorEl}
  //       anchorOrigin={{ vertical: "top", horizontal: "right" }}
  //       id={menuId}
  //       keepMounted
  //       transformOrigin={{ vertical: "top", horizontal: "right" }}
  //       open={isMenuOpen}
  //       onClose={handleMenuClose}
  //       onClick={handleClickMenu}
  //     >
  //       <MenuItem key={0} onClick={handleMenuClose}>
  //         Mi cuenta:
  //       </MenuItem>
  //       <MenuItem key={1} onClick={handleSesionClose}>
  //         Cerrar sesion
  //       </MenuItem>
  //     </Menu>
  //   );
  // const mobileMenuId = "primary-search-account-menu-mobile";
  // const renderMobileMenu = () => {
  //   console.log(props.user.isLogin);
  //   props.user.isLogin === false ? (
  //     <Menu
  //       anchorEl={mobileMoreAnchorEl}
  //       anchorOrigin={{ vertical: "top", horizontal: "left" }}
  //       id={mobileMenuId}
  //       keepMounted
  //       transformOrigin={{ vertical: "top", horizontal: "right" }}
  //       open={isMobileMenuOpen}
  //       onClose={handleMobileMenuClose}
  //     >
  //       <MenuItem onClick={handleIniciarSesion}>Iniciar Sesion</MenuItem>
  //     </Menu>
  //   ) : (
  //     <Menu
  //       anchorEl={mobileMoreAnchorEl}
  //       anchorOrigin={{ vertical: "top", horizontal: "right" }}
  //       id={mobileMenuId}
  //       keepMounted
  //       transformOrigin={{ vertical: "top", horizontal: "right" }}
  //       open={isMobileMenuOpen}
  //       onClose={handleMobileMenuClose}
  //     >
  //       <MenuItem>
  //         <IconButton
  //           aria-label="mensajes"
  //           color="inherit"
  //           onClick={openMessageMenu}
  //         >
  //           <Badge
  //             badgeContent={
  //               props.user.isLogin === true ? props.user.messages.length : null
  //             }
  //             color="secondary"
  //           >
  //             <MailIcon />
  //           </Badge>
  //         </IconButton>
  //       </MenuItem>
  //       <MenuItem key={0} onClick={handleMenuClose}>
  //         <IconButton
  //           aria-label="mensajes"
  //           color="inherit"
  //           onClick={openMessageMenu}
  //         >
  //           <Badge badgeContent={props.user.messages.length} color="secondary">
  //             <AccountCircle />
  //           </Badge>
  //         </IconButton>
  //       </MenuItem>
  //       <MenuItem key={1} onClick={handleSesionClose}>
  //         <IconButton
  //           aria-label="mensajes"
  //           color="inherit"
  //           onClick={openMessageMenu}
  //         >
  //           <Badge badgeContent={props.user.messages.length} color="secondary">
  //             <ExitToAppIcon />
  //           </Badge>
  //         </IconButton>
  //       </MenuItem>
  //     </Menu>
  //   );
  // };
  return (
    <div>
      <div className="header-container">
        <AppBar position="static">
          <Toolbar>
            {/* {props.user.isLogin ? (
              <IconButton
                edge="start"
                className="menu-button"
                color="inherit"
                aria-label="open drawer"
                onClick={props.user.isLogin ? callback(true) : null}
              >
                <MenuIcon />
              </IconButton>
            ) : null} */}

            <Typography
              className="title"
              variant="h6"
              noWrap
              onClick={() => {
                history.push("/");
              }}
            >
              MiPetnow
            </Typography>
            {/* <div className={classes.sectionButtonDesktop}>
              <Button
                edge="start"
                className={classes.buttonDesktop}
                color="inherit"
                aria-label="open drawer"
                onClick={goToExploreTiendas}
              >
                <StorefrontRoundedIcon /> Tiendas
              </Button>
              <Button
                edge="start"
                className={classes.buttonDesktop}
                color="inherit"
                aria-label="open drawer"
                onClick={goToExploreClinicas}
              >
                <PetsRoundedIcon /> Clinicas
              </Button>
            </div> */}

            {/* <div className={classes.grow} /> */}
            {/* <div className={classes.sectionDesktop}>
              {!props.user.isLogin ? (
                <div></div>
              ) : (
                <div>
                  <IconButton
                    aria-label="mensajes"
                    color="inherit"
                    onClick={openMessageMenu}
                  >
                    <Badge
                      badgeContent={
                        props.user.isLogin &&
                        props.user.messages &&
                        props.user.messages.length
                      }
                      color="secondary"
                    >
                      <MailIcon />
                    </Badge>
                  </IconButton>
                  <IconButton
                    aria-label="show 17 new notifications"
                    color="inherit"
                  >
                    <Badge
                      badgeContent={
                        props.user.isLogin &&
                        props.user.notifications &&
                        props.user.notifications.length
                      }
                      color="secondary"
                    >
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </div>
              )}

              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div> */}
            {/* <div className={classes.sectionMobile}>
              {!props.user.isLogin ? (
                 <IconButton
                 edge="end"
                 aria-label="account of current user"
                 aria-controls={menuId}
                 aria-haspopup="true"
                 onClick={handleProfileMenuOpen}
                 color="inherit"
                 className="button-user"
               >
                 <AccountCircle fontSize="medium"/>
                 <p>Login</p>
               </IconButton>
              ) : (
                <div>
                  <IconButton
                    aria-label="mensajes"
                    color="inherit"
                    onClick={openMessageMenu}
                  >
                    <Badge
                      badgeContent={
                        props.user.isLogin &&
                        props.user.messages &&
                        props.user.messages.length
                      }
                      color="secondary"
                    >
                      <MailIcon />
                    </Badge>
                  </IconButton>
                  <IconButton
                    aria-label="show 17 new notifications"
                    color="inherit"
                  >
                    <Badge
                      badgeContent={
                        props.user.isLogin &&
                        props.user.notifications &&
                        props.user.notifications.length
                      }
                      color="secondary"
                    >
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                  
                </div>
              )}
            </div> */}
          </Toolbar>
        </AppBar>
        {/* {renderMobileMenu}
        {renderMenu}
        {messageMenu} */}
      </div>
    
      {/* <AuthLive
        handleCloseModal={handleCloseModal}
        open={open}
        setUser={setUser}
      ></AuthLive> */}
    </div>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
