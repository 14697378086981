import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ProviderCard from "../../Components/ProviderCard/ProviderCard";
import { Header } from "../../Header/Header";
import "./PageInit.scss";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
// import {
//   getUserAction,
//   setProfesionalAction,
//   setUserAction,
// } from "../../User/userAction";
import {
  getProfessionalsProfilesData,
  handlerSuscribe,
} from "./PageInitService";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import {
  getProfessionalProfilesAction,
  setProfessionalProfilesAction,
} from "./PageInitAction";
import { Loader } from "../../Components/Loader/Loader";
import { Button, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import detectBrowserLanguage from "detect-browser-language";
import img from "./banner-landing.jpeg";
import { TextFieldsOutlined } from "@material-ui/icons";
import { ValidateEmail } from "Common/utils";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 220,
    width: 350,
    position: "relative",
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => ({
  // getUserAction: () => dispatch(getUserAction),
  // setUserAction: (data) => dispatch(setUserAction(data)),
  // getProfessionalProfilesAction: () => dispatch(getProfessionalProfilesAction),
  // setProfessionalProfilesAction: (data) =>
  //   dispatch(setProfessionalProfilesAction(data)),
});

export const PageInit = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [isLoading, setisLoading] = useState(false);
  const [Professionals, setProfessionals] = useState([]);
  const [spacing, setSpacing] = React.useState(4);
  const classes = useStyles();
  const [Email, setEmail] = useState("");

  const handleChange = (event) => {
    setSpacing(Number(event.target.value));
  };

  const onClickPromo = (id, tienda) => {
    console.log(id);
  };
  const recaptchaRef = React.useRef();

  const onSubmitWithReCAPTCHA = async () => {
    const token = await recaptchaRef.current.executeAsync();
    console.log('token : ',token)
    // apply to form data
  };
  //Creating a method to change the language onChnage from select box
  const changeLanguageHandler = (e) => {
    const languageValue = e.target.value;
    i18n.changeLanguage(languageValue);
  };
  useEffect(() => {
    const lenguage = detectBrowserLanguage();
    const short = lenguage.split("-");
    console.log(short);
    i18n.changeLanguage(short[0]);
  }, []);
  const [StateSuscribe, setStateSuscribe] = useState({ msg: "", ok: false });
  const handleOnChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleRegisterEmail = async () => {
    // const token = await recaptchaRef.current.executeAsync();
    // console.log(token)
    if(StateSuscribe.ok) return;
    const comp = ValidateEmail(Email);
    console.log(comp)
    if(!comp) return;
    handlerSuscribe({ email: Email })
      .then((resp) => resp.data)
      .then((resp) => {
        console.log(resp);
        if (resp.errors && resp.errors.email.kind === "unique") {
          console.log("resp.errors.email.kind : ", resp.errors.email.kind);
          setStateSuscribe({
            msg: "Ya estabas suscrito, pero gracias otra vez!!",
            ok: true,
          });
          return;
        }
        setStateSuscribe({
          msg: "Gracias por suscribirte, te tendremos novedades!",
          ok: true,
        });
      })
      .catch((err) => {
        // if(err)
        setStateSuscribe({
          msg: "Hemos tenido algunos problemas, pero puedes intentarlo nuevamente, si esto persiste, no dudes en enviarnos un correo a hello@mipetnow.com!",
          ok: false,
        });

        console.log("err: ", err);
      });
  };
  return (
    <div className="page-init">
      {isLoading ? <Loader></Loader> : null}
      <div className="page-init__landing-page">
        <div className="section-image">
          <img
            src="https://mipetnow-bck.s3.us-west-2.amazonaws.com/mvp/banner-landing.jpeg"
            alt="imagen"
          ></img>
        </div>
        <div className={"text"}>
          <div className={"container"}>
            <div>
              <h2>Todo para tu mascota, en un solo lugar.</h2>
              <p>Pronto, la plataforma que te ayudara y te apoyara, con tus mascotas, en la palma de tus manos</p>
              {/* <h2>En Noviembre</h2>
              <p className={"parrafo"}>
                {" "}
                La plataforma más grande de mascotas llegara, a la palma de tus
                manos.
              </p> */}
            </div>
          </div>
        </div>
        <div className={"form"}>
          <div className={"container"}>
            <div>
              <h2>Suscribete para estar atento al lanzamiento de MipetNow.</h2>
              <p>
                Solo usaremos tu correo electronico para avisarte en el momento
                de lanzamiento.
              </p>
              <div>
                {!StateSuscribe.ok ? <TextField
                  label="Email"
                  type="email"
                  className={"input"}
                  variant="outlined"
                  value={Email}
                  fullWidth
                  onChange={(e) => {
                    handleOnChangeEmail(e);
                  }}
                ></TextField>
                : <h3>{StateSuscribe.msg}</h3> }
                {/* <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6Le0liQdAAAAAMWvJf3OM2buBEtIKyZKs2_xxXif"
                /> */}
                 {/* <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6Le0liQdAAAAAMWvJf3OM2buBEtIKyZKs2_xxXif"
                  onChange={()=> console.log('test')}
                /> */}
              </div>
            </div>
            <div className={"button"}>
              <Button
                variant="contained"
                className={"btn"}
                fullWidth
                disabled={StateSuscribe.ok}
                onClick={() => {
                  handleRegisterEmail();
                }}
              >
                Registrarme
              </Button>
            </div>
          </div>
        </div>
        <div className={"rrss"}>
          <div className={"title"}>
            <h3>
              Siguenos en nuestras redes sociales para estar atento a novedades.
            </h3>
          </div>
          <div className={"container"}>
            <div className={"container"}>
              <a href="https://www.linkedin.com/company/mipetnow">
                <img
                  src="https://mipetnow-bck.s3.us-west-2.amazonaws.com/mvp/logo-in.png"
                  alt=""
                ></img>
              </a>
            </div>
            <div className={"container"}>
              <a href="https://www.facebook.com/Mipetnow-270584211454444">
                <img
                  src="https://mipetnow-bck.s3.us-west-2.amazonaws.com/mvp/logo-fb.svg"
                  alt=""
                ></img>
              </a>
            </div>
            <div className={"container"}>
              <a href="https://www.instagram.com/mipetnow/">
                <img
                  src="https://mipetnow-bck.s3.us-west-2.amazonaws.com/mvp/logo-insta.png"
                  alt=""
                ></img>
              </a>
            </div>
          </div>
        </div>
        <div className={"contact"}>
          <div>
            <h3>By MIPETNOW</h3>
          </div>
          <div>
            <a href="mailto:hello@mipetnow.com">HELLO@MIPETNOW.COM</a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PageInit);
