import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import { connect } from "react-redux";
import "./App.scss";
import { compose } from "redux";
// import Auth from "./Pages/Auth/Auth";
// import LoginEmail from "./Pages/LoginEmail/LoginEmail";
import PageInit from "./Pages/PageInit/PageInit";
import { Header } from "./Header/Header";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import GridOnIconOutlined from "@material-ui/icons/GridOnOutlined";
import ApartmentOutlinedIcon from "@material-ui/icons/ApartmentOutlined";
// import {
//   setUserAction,
//   getUserAction,
//   getProfesionalAction,
//   setProfesionalAction,
// } from "./User/userAction";
import FaceIcon from "@material-ui/icons/Face";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import StoreRoundedIcon from "@material-ui/icons/StoreRounded";
import QuestionAnswerRounded from "@material-ui/icons/QuestionAnswerRounded";
import MessageIcon from "@material-ui/icons/Message";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { useHistory } from "react-router-dom";

import './i18n';
const mapStateToProps = (state) => {
  return state;
};

/**
 * recargar pagina
 * ir a buscar data de usuario.
 * reemplazar userInLs (usuario en local storage)
 *
 *
 */
const mapDispatchToProps = (dispatch) => {
  // return {
  //   getUserAction: () => dispatch(getUserAction),
  //   setUserAction: (data) => dispatch(setUserAction(data)),
  //   getProfesionalAction: () => dispatch(getProfesionalAction),
  //   setProfesionalAction: (data) => dispatch(setProfesionalAction(data)),
  // };
};

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});
const itemDrawer = [
  {
    title: "Perfil",
    icon: () => {
      return <FaceIcon></FaceIcon>;
    },
  },
];
export const App = (props) => {
  let history = useHistory();
  const classes = useStyles();
  const [stateDrawer, setstateDrawer] = useState(false);
  const [isLoged, setisLoged] = useState(false);
  const [userInLS, setuserInLS] = useState(async () => {
    console.log("USER IN LS")
    try {
      const item = localStorage.getItem("user");
      const dataLocal = JSON.parse(item);
      if (dataLocal.email) {
        let data = JSON.parse(item);
        // var dataN = await getLogin({
        //   email: data.email,
        //   token: data.token,
        //   type:data.type
        // });
        // dataN = { ...dataN, isLogin: true };
        // await props.setUserAction({ ...dataN });
        // await localStorage.setItem("user", JSON.stringify(dataN));
      }
      else{
        await props.setUserAction({ isLogin:false });
      }
      return props.user;
    } catch (err) {
      console.warn("Setting localStorage went wrong: ", err);
      return props.value;
    }
  });
  let { path, url } = useRouteMatch();
  const toggleDrawer = (open) => (event) => {
    if (props.user.isLogin === false) return;
    setisLoged(props.user.isLogin);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    //if(open){

    //}
    setstateDrawer(open);
  };

  return (
    <div className="App-container">
      <Header callback={toggleDrawer} {...props} {...userInLS}></Header>
      <div>
        {/* <React.Fragment key={"left"}>
          <Drawer
            anchor={"left"}
            open={stateDrawer}
            onClose={toggleDrawer(false)}
          >
            {list("left")}
          </Drawer>
        </React.Fragment> */}
      </div>
      <Switch>
        <Route path="/auth">
          {/* <AuthLive /> */}
        </Route>
        <Route path="/loginEmail">
          {/* <LoginEmail /> */}
        </Route>
        <Route path="/register">
          {/* <Register /> */}
        </Route>
        <Route path="/">
          <PageInit {...props} {...userInLS} />
        </Route>
      </Switch>
    </div>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
