import axios from "axios"

export const getProfessionalsProfilesData = async (params) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
  };
  console.log("getProfessionalsProfilesData : ", requestOptions);
  const data = await fetch(
    "https://o6p2brh7ml.execute-api.us-west-2.amazonaws.com/dev",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      console.log("data", data);
      return data;
    });
  return data;
};


export async function handlerSuscribe({email}) {
    console.log({email})
     const url = 'https://api.mipetnow.com/suscribe';
    const payload = {email};
    return await axios.post(url,payload).then((resp)=>{
        return resp;
    }).catch((err)=>{
        return err
    })
  }
  