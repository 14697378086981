import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./ProviderCard.scss";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useState } from "react";
// import { getProfesionalAction, getUserAction, setProfesionalAction, setUserAction } from "../../User/userAction";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "@material-ui/core";
import DateTimePicker from "react-datetime-picker";
import { Calendar } from "../Calendar/Calendar";
import { Label } from "@material-ui/icons";
import {useHistory, useLocation, useParams} from 'react-router-dom';

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => ({
  // getUserAction: () => dispatch(getUserAction()),
  // getProfesionalAction: () => dispatch(getProfesionalAction),
  // setProfesionalAction: (data) => dispatch(setProfesionalAction(data))
});

/**
 * @name ProviderCard
 * @param {*} props
 */

const useStyles = makeStyles((theme) => ({
  root: {
    //maxWidth: 345,
    //borderRadius: "22px",
  },
  media: {
    //height: "100px",
    //paddingTop: "42px", //"56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const ProviderCard = (props) => {
  const { displayName, description,nameClinica, photo_professional, tagName } = props.prov;
  console.log(props.prov);
  const { callback ,isPrivate} = props;
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLike, setisLike] = useState(false);
  const [isLoged, setisLoged] = useState(false);

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const [value, onChange] = useState(new Date());

  const bodyContact = (
    <div
      style={
        {
          // width: "50%",
          // top: "25%",
          // left: "25%",
        }
      }
      className="modal-contact"
    >
      <h2 id="simple-modal-title">Hola soy profesional!</h2>
      <p id="simple-modal-description">
        Dejame un mensaje con la siguiente informacion:
      </p>
      <div className="form-contact">
        <div>
          <TextField
            id="tipotattoo"
            label="Tipo Tatuaje"
            defaultValue=""
            helperText="Blanco, negro, etc."
            fullWidth
          />
        </div>
        <div>
          <TextField
            id="tipotattoo"
            label="Tamaño de tatuaje"
            defaultValue=""
            helperText="cm: ancho y largo."
            fullWidth
          />
        </div>
        <div>
          <TextField
            id="tipotattoo"
            label="Referencia, descripcion, cuentame! "
            defaultValue=""
            helperText="cuentame sobre tu tatuaje"
            fullWidth
          />
        </div>
        <div>
          <p>Alguna imagen de referencia?</p>
          <Button variant="contained" component="label" fullWidth>
            Upload File
            <input type="file" hidden />
          </Button>
        </div>
        <div>
          <TextField
            id="zona"
            label="Zona del cuerpo"
            defaultValue=""
            helperText="Ante Brazo, Muslo, etc."
            fullWidth
          />
        </div>
        <div>
          <TextField
            id="escoverup"
            label="Es cover up?"
            defaultValue=""
            helperText="Si - No"
            fullWidth
          />
        </div>
        <div className="">
          <Button variant="contained" component="label" fullWidth>
            Contactar
          </Button>
        </div>
      </div>
   {/* <Calendar>   </Calendar> */}
    </div>
  );
  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }

  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
      // top: `${top}%`,
      // left: `${left}%`,
      // transform: `translate(-${top}%, -${left}%)`,
      display: "flex",
      justifyContent: "center",
      //alignItems: "center",
    };
  }

  const handleClick = (event) => {
    const user = props.data.user;
    setisLoged(user.isLogin);
    //setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setIsLike = () => {
    setisLike(!isLike);
  };
  function showModal(e) {
    //callback(props.prov);
    //handleOpenModal();
    e.stopPropagation();
  }

  const handleClickContactar = () => {
    handleOpenModal();
    handleClose();
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  const toPageProfesional = (e) => {
    //if(!isPrivate) {
      props.setProfesionalAction({...props.prov});
    history.push(`profile-professional/${tagName}`);
   // }else{
   //   return;
    //}
  }
  const  history = useHistory();
  let { id } = useParams();

  return (
    <div className="provider--card--container">
      <Card
        className={classes.root}
        className="card_container"
        style={{
          backgroundImage: `url(${photo_professional})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        onClick={(e) => {
          
        }}
      >
        <CardHeader
          avatar={
            <div className="avatar-name">
              <Avatar aria-label="recipe" className={classes.avatar}>
                {nameClinica.slice(0, 1)}
              </Avatar>
              <p className="name">
                {nameClinica.length >= 20 ? nameClinica.substring(0, 19) + "..." : nameClinica}
              </p>
            </div>
          }
        >
          <p>Hola</p>
        </CardHeader>

        {isLoged !== false ? (
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className="menu-options"
          >
            <MenuItem onClick={handleClose}>Guardar</MenuItem>
            <MenuItem onClick={handleClickContactar}>Contactar</MenuItem>
            <MenuItem onClick={handleClose}>Reportar</MenuItem>
          </Menu>
        ) : (
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Debes iniciar sesion</MenuItem>
          </Menu>
        )}
        {/* {img ? <CardMedia className={classes.media} /> : null} */}
        <CardContent></CardContent>
        <CardActions className="card-actions">
          <div>
            <IconButton aria-label="add to favorites"  onClick={setIsLike}>
              <FavoriteIcon
               
                className={isLike === true ? "prov-like" : ""}
              />
            </IconButton>
            <IconButton aria-label="share">
              <ShareIcon />
            </IconButton>
          </div>
          <IconButton
            className="button"
            aria-label="settings"
            onClick={toPageProfesional}
          >
            <MoreVertIcon />
          </IconButton>
        </CardActions>
      </Card>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>{bodyContact}</div>
      </Modal>
    </div>
  );
};

ProviderCard.propTypes = {};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProviderCard);
