import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import configureStore from "./redux/store/configureStore";
import { Provider as ReduxProvider } from "react-redux";
import { createStore } from "redux";
import App from "./App";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

// import reportWebVitals from './reportWebVitals';
const store = configureStore(window.REDUX_DATA);
// const store = createStore(reducer.default,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
ReactDOM.hydrate(
  <ReduxProvider store={store}>
    {/* <React.StrictMode> */}
    <Helmet>
      <meta charSet="utf-8" />
      <title>Mipetnow</title>
      <meta name="Content-Type" content="text/html; charset=utf-8" />

      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <meta
        http-equiv="Content-Security-Policy"
        content="upgrade-insecure-requests"
      ></meta>
      <meta
        name="description"
        content="Todo para tu mascota, en un solo lugar."
      />
      <link rel="icon" href="/favicon.ico" />
      <link rel="canonical" href="https://mipetnow.com" />
    </Helmet>
    <Router>
      <App />
    </Router>
    {/* </React.StrictMode> */}
  </ReduxProvider>,
  document.getElementById("root")
);

// ReactDOM.render(
//   <ReduxProvider store={store}>
//     {/* <React.StrictMode> */}
//     <Helmet>
//       <meta charSet="utf-8" />
//       <title>MiPetNow</title>
//       <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta>
//       <link rel="canonical" href="http://dev.mipetnow.com" />
//     </Helmet>
//     <Router>
//       <App />
//     </Router>
//     {/* </React.StrictMode> */}
//   </ReduxProvider>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log("Hola"));
